import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';
import useIsGestionnaire from '@/hooks/useIsGestionnaire';
import useItems from '@/hooks/useItems';
import useSetFormValues from '@/hooks/useSetFormValues';
import useConditionalRequired from '@/hooks/useConditionalRequired';

import DatePickerField from '@/components/controlled-fields/DatePickerField';
import SelectField from '@/components/controlled-fields/SelectField';
import SwitchField from '@/components/controlled-fields/SwitchField';
import AreaTextField from '@/components/controlled-fields/text-fields/AreaTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import CodePostalTextField from '@/components/controlled-fields/text-fields/CodePostalTextField';
import MailTextField from '@/components/controlled-fields/text-fields/MailTextField';
import UpperCaseTextField from '@/components/controlled-fields/text-fields/UpperCaseTextField';
import DividerTitle from '@/components/DividerTitle';
import TabPanelForm from '@/components/TabPanelForm';

import AdresseField from '@/features/shared/AdresseField';
import DepartementField from '@/features/shared/DepartementField';
import ContratProprietaireAutocompleteField from '@/features/shared/ContratProprietaireAutocompleteField';
import GestionnaireAutocompleteField from '@/features/shared/GestionnaireAutocompleteField';
import IndivisaireReferentSelect from '@/features/shared/indivisaire/IndivisaireReferentSelect';
import ReferentTechniqueFormDialog from '@/features/shared/referent-technique/ReferentTechniqueFormDialog';
import IndivisaireTable from '@/features/shared/indivisaire/IndivisaireTable';
import ParcelleCadastralePressentieSousContratTable from '@/features/shared/parcelle-cadastrale-pressentie-sous-contrat/ParcelleCadastralePressentieSousContratTable';
import ProprietaireTable from '@/features/shared/proprietaire/ProprietaireTable';

import { setFilteredItems } from '@/slices/itemSlice';

import APIService from '@/APIService';

export default function RenseignementsGenerauxTab({
  form,
  tabValue,
  choices,
  proprieteState,
  gestionnaireState,
  contratProprietaireState,
  isIndivisaireState,
  proprietaireState,
  proprietairesList,
  indivisairesList,
  parcellesList,
  emptyContact,
  contratId,
}) {
  const {
    control: indivisaireReferentControl,
    setValue: setIndivisaireReferentSelectValue,
  } = useForm({
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const fetchFilteredItems = useFetchFilteredItems();
  const { control, setValue, trigger, getValues } = form;
  const { contratProprietaire, setContratProprietaire } =
    contratProprietaireState;
  const { isIndivisaire, setIsIndivisaire } = isIndivisaireState;
  const { proprietaire, setProprietaire } = proprietaireState;
  const { gestionnaire, setGestionnaire } = gestionnaireState;
  const { propriete, setPropriete } = proprieteState;
  const [isDifferentReferent, setIsDifferentReferent] = useState(false);
  const [referentIsIndivisaire, setReferentIsIndivisaire] = useState(false);

  const dispatch = useDispatch();
  const setFormValues = useSetFormValues();

  const user = useSelector((store) => store.persistAuthReducer.user);

  const isGestionnaire = useIsGestionnaire();
  const gestionnaires = isGestionnaire ? [] : useItems('gestionnaires_pro');
  const usersList = isGestionnaire ? [] : useItems('users');

  const cmfUsers = useMemo(() => {
    let users;
    if (isGestionnaire) {
      users = [contratProprietaire?.propriete?.cmf_neosylva];
    } else {
      users = usersList?.filter((user) => user.role.name === 'neosylva_cmf');
    }
    return users;
  }, [contratProprietaire, usersList]);
  

  const [gestionnaireWatch, referentTechniqueWatch] = useWatch({
    control,
    name: ['propriete.gestionnaire', 'contrat_proprietaire.referent_technique'],
  });

  const conditionalRequiredFields = [
    ['proprietaire.contact.telephone_fixe'],
    ['proprietaire.contact.telephone_portable'],
    ['proprietaire.contact.email'],
  ];

  const requiredMapper = useConditionalRequired(
    conditionalRequiredFields,
    control,
    trigger
  );

  const proprietaireFields = [
    ['nom', true, setValue, 'contact'],
    ['prenom', true, setValue, 'contact'],
    ['telephone_fixe', false, setValue, 'contact'],
    ['telephone_portable', true, setValue, 'contact'],
    ['email', true, setValue, 'contact'],
    ['adresse', true, setValue, 'contact'],
    ['commune', true, setValue, 'contact'],
    ['code_postal', true, setValue, 'contact'],
    ['departement', true, setValue, 'contact'],
    ['personnalite_juridique', true, setValue],
    ['raison_sociale', false, setValue],
    ['siret', false, setValue],
    ['adresse_siege', false, setValue],
    ['code_postal_siege', false, setValue],
    ['commune_siege', false, setValue],
  ];

  const contratsProprietaires = useSelector(
    (store) => store.itemReducer.filteredItems.contrats_proprietaires
  );

  const clearIndivisaireReferent = () => {
    setIndivisaireReferentSelectValue('indivisaire', null);
    setValue('contrat_proprietaire.referent_technique', emptyContact, {
      shouldValidate: true,
    });
  };

  const fetchProprietaire = async (proprietaireId) => {
    const proprietaireRes = await APIService.get({
      url: `proprietaires/${proprietaireId}`,
    });
    setProprietaire(proprietaireRes.data);
  };

  const fetchPropriete = async (proprieteId) => {
    const proprieteRes = await APIService.get({
      url: `proprietes/${proprieteId}`,
    });
    setPropriete(proprieteRes.data);
  };

  const indivisaireReferentSelectWatch = useWatch({
    control: indivisaireReferentControl,
    name: 'indivisaire',
  });

  useEffect(() => {
    if (!isIndivisaire && proprietaire?.indivisaire) {
      // Existing indivisaire
      setValue('proprietaire.indivisaire', null);
    } else if (isIndivisaire) {
      if (!proprietaire.indivisaire) {
        // New indivisaire
        setValue(
          'proprietaire.indivisaire.indivision.nom',
          `Indivision ${proprietaire.contact.nom}`,
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
        dispatch(
          setFilteredItems({
            itemsList: [
              {
                proprietaire,
                principale: true,
                disabledEdit: true,
              },
            ],
            itemType: 'indivisaires',
          })
        );
      } else {
        setValue(
          'proprietaire.indivisaire.indivision.nom',
          proprietaire.indivisaire.indivision.nom,
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
      }
    }
  }, [isIndivisaire]);

  useEffect(() => {
    if (!proprietaire) {
      return;
    }

    setFormValues(proprietaireFields, proprietaire, 'proprietaire');

    setIsIndivisaire(proprietaire.indivisaire !== null);

    if (proprietaire.indivisaire) {
      const indivision = proprietaire.indivisaire.indivision;

      fetchFilteredItems({
        filter: { indivision_id: indivision.id },
        itemTypes: ['indivisaires'],
      });

      setValue('proprietaire.indivisaire.indivision.nom', indivision.nom, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue(
        'proprietaire.indivisaire.nature_indivisaire',
        proprietaire.indivisaire.nature_indivisaire,
        {
          shouldDirty: true,
          shouldValidate: true,
        }
      );
    }
  }, [proprietaire]);

  useEffect(() => {
    gestionnaireWatch && setGestionnaire(gestionnaireWatch);
  }, [gestionnaireWatch]);

  useEffect(() => {
    if (!contratProprietaire) {
      return;
    }

    let mainProprietaire;
    const mainProprietaireId = contratProprietaire.proprietaire_principale.id;
    if (mainProprietaireId) {
      mainProprietaire = contratProprietaire.proprietaires_list.find(
        (proprietaire) => proprietaire.id === mainProprietaireId
      );
    } else {
      mainProprietaire = contratProprietaire.proprietaires_list[0];
    }
    fetchProprietaire(mainProprietaire.id);
    fetchPropriete(contratProprietaire.propriete.id);
    setGestionnaire(propriete?.gestionnaire);

    dispatch(
      setFilteredItems({
        itemsList: contratProprietaire.sharepoints_list.concat(
          contratProprietaire.files_list
        ),
        itemType: 'documents',
      })
    );

    fetchFilteredItems({
      filter: {
        item_type: 'contrat_proprietaire',
        item_id: contratProprietaire.id,
      },
      itemTypes: ['unites_gestion'],
    });

    setIsDifferentReferent(
      contratProprietaire.referent_technique
        ? contratProprietaire.referent_technique.id !==
            mainProprietaire.contact.id
        : false
    );

    setValue('contrat_proprietaire', contratProprietaire, {
      shouldValidate: true,
    });
    return () => {
      clearIndivisaireReferent();
      setReferentIsIndivisaire(false);
      setIsDifferentReferent(false);
      setProprietaire(null);
      setPropriete(null);
    };
  }, [contratProprietaire]);

  useEffect(() => {
    if (indivisaireReferentSelectWatch) {
      setValue(
        'contrat_proprietaire.referent_technique',
        indivisaireReferentSelectWatch.proprietaire.contact,
        { shouldValidate: true }
      );
      setReferentIsIndivisaire(true);
    } else {
      setReferentIsIndivisaire(false);
    }
  }, [indivisaireReferentSelectWatch]);

  useEffect(() => {
    if (contratsProprietaires || contratId) {
      return;
    }
    fetchFilteredItems({
      filter: { item_type: 'diagnostic', item_id: user?.gestionnaire_id },
      itemTypes: ['contrats_proprietaires'],
    });
  }, [contratsProprietaires]);

  useEffect(() => {
    if (isDifferentReferent) {
      if (
        getValues('contrat_proprietaire.referent_technique.id') ===
        proprietaire?.contact?.id
      ) {
        setValue('contrat_proprietaire.referent_technique', emptyContact, {
          shouldValidate: true,
        });
      }
    } else {
      setIndivisaireReferentSelectValue('indivisaire', null);
      setValue(
        'contrat_proprietaire.referent_technique',
        proprietaire?.contact
      );
      trigger('contrat_proprietaire.referent_technique.nom');
    }
  }, [isDifferentReferent]);

  useEffect(() => {
    if (
      indivisairesList &&
      contratProprietaire?.referent_technique &&
      !referentIsIndivisaire
    ) {
      const indivisaireReferent = indivisairesList.find(
        (indivisaire) =>
          indivisaire.proprietaire.contact.id ===
            contratProprietaire.referent_technique.id &&
          indivisaire.proprietaire.id !== proprietaire?.id
      );
      if (indivisaireReferent) {
        setIndivisaireReferentSelectValue('indivisaire', indivisaireReferent);
        setReferentIsIndivisaire(true);
      }
    }
  }, [indivisairesList]);

  const personnaliteJuridique = useWatch({
    control,
    name: 'proprietaire.personnalite_juridique.code',
  });
  const codePostalProprietaireWatch = useWatch({
    control,
    name: 'proprietaire.contact.code_postal',
  });
  const codePostalProprieteWatch = useWatch({
    control,
    name: 'propriete.code_postal',
  });
  const onClearPersonnaliteJuridique = () =>
    setValue('proprietaire.personnalite_juridique', null, {
      shouldValidate: true,
    });

  useEffect(() => {
    !codePostalProprietaireWatch &&
      setValue(
        'proprietaire.contact.departement',
        proprietaire?.contact.departement
      );
  }, [codePostalProprietaireWatch]);

  return (
    <TabPanelForm currentValue={tabValue} index={0}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            {!contratId && (
              <ContratProprietaireAutocompleteField
                currentContratProprietaire={contratProprietaire}
                contratsProprietaires={contratsProprietaires}
                setContratProprietaire={setContratProprietaire}
              />
            )}
          </Grid>
        </Grid>
        {proprietaire &&
          (!contratId ? (
            <>
              <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
                <Grid item xs={12}>
                  <DividerTitle text={'Informations propriétaire'} />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
                <Grid item xs={12} sm={6} md={3}>
                  <BaseTextField
                    control={control}
                    name='proprietaire.contact.nom'
                    maxLength={256}
                    label='Nom'
                    required='Nom requis.'
                    disabled={isGestionnaire}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <BaseTextField
                    control={control}
                    name='proprietaire.contact.prenom'
                    maxLength={256}
                    label='Prénom'
                    required='Prénom requis.'
                    disabled={isGestionnaire}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
                <Grid item xs={12} sm={7} md={5}>
                  <AdresseField
                    control={control}
                    fieldName={'proprietaire.contact.adresse'}
                    relatedFiedlNames={{
                      commune: 'proprietaire.contact.commune',
                      codePostal: 'proprietaire.contact.code_postal',
                    }}
                    label={'Adresse propriétaire'}
                    setValue={setValue}
                    disabled={isGestionnaire}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <BaseTextField
                    control={control}
                    name='proprietaire.contact.commune'
                    label='Commune propriétaire'
                    maxLength={256}
                    disabled={isGestionnaire}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <CodePostalTextField
                    control={control}
                    name='proprietaire.contact.code_postal'
                    label='Code postal propriétaire'
                    disabled={isGestionnaire}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <DepartementField
                    control={control}
                    label={'Département propriétaire'}
                    departements={choices?.departements}
                    departementFieldName={'proprietaire.contact.departement'}
                    codePostalFieldName={'proprietaire.contact.code_postal'}
                    setValue={setValue}
                    required={
                      !codePostalProprietaireWatch &&
                      'Département propriétaire requis.'
                    }
                    disabled={!!codePostalProprietaireWatch || isGestionnaire}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
                <Grid item xs={12} sm={6} md={2}>
                  <BaseTextField
                    control={control}
                    name='proprietaire.contact.telephone_fixe'
                    maxLength={16}
                    label='Téléphone fixe'
                    required={
                      requiredMapper.telephone_fixe &&
                      'Téléphone fixe requis.'
                    }
                    disabled={isGestionnaire}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <BaseTextField
                    control={control}
                    name='proprietaire.contact.telephone_portable'
                    maxLength={16}
                    label='Téléphone portable'
                    required={
                      requiredMapper.telephone_portable &&
                      'Téléphone portable requis.'
                    }
                    disabled={isGestionnaire}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <MailTextField
                    control={control}
                    name='proprietaire.contact.email'
                    required={requiredMapper.email && 'E-mail requis.'}
                    maxLength={120}
                    label='E-mail'
                    disabled={isGestionnaire}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
                <Grid item xs={12} sm={6} md={3}>
                  <SelectField
                    control={control}
                    name='proprietaire.personnalite_juridique'
                    label='Personnalité juridique'
                    renderValue={(value) => value.nom}
                    values={choices?.personnalites_juridiques}
                    itemMapper={(personnalite_juridique) => ({
                      key: personnalite_juridique.code,
                      label: personnalite_juridique.nom,
                    })}
                    baseId='personnalite-juridique'
                    disabled={isGestionnaire}
                    onClear={onClearPersonnaliteJuridique}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
                {personnaliteJuridique === 'PM' && (
                  <>
                    <Grid item xs={12} sm={6} md={2}>
                      <BaseTextField
                        control={control}
                        name='proprietaire.raison_sociale'
                        maxLength={256}
                        label='Raison sociale'
                        disabled={isGestionnaire}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <BaseTextField
                        control={control}
                        name='proprietaire.siret'
                        label='SIRET'
                        fixedLength={{
                          value: 14,
                          message: 'Le SIRET doit avoir 14 caractères',
                        }}
                        disabled={isGestionnaire}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <AdresseField
                        control={control}
                        fieldName={'proprietaire.adresse_siege'}
                        relatedFiedlNames={{
                          commune: 'proprietaire.commune_siege',
                          codePostal: 'proprietaire.code_postal_siege',
                        }}
                        required={false}
                        label={'Adresse siège'}
                        setValue={setValue}
                        disabled={isGestionnaire}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                      <CodePostalTextField
                        control={control}
                        name='proprietaire.code_postal_siege'
                        label='Code postal siège'
                        disabled={isGestionnaire}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <BaseTextField
                        control={control}
                        name='proprietaire.commune_siege'
                        label='Commune siège'
                        maxLength={256}
                        disabled={isGestionnaire}
                      />
                    </Grid>
                  </>
                )}
                {personnaliteJuridique === 'PP' && (
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isIndivisaire}
                          onChange={(e) => setIsIndivisaire(e.target.checked)}
                          disabled={isGestionnaire}
                        />
                      }
                      label='Indivisaire'
                    />
                  </Grid>
                )}
                {isIndivisaire && personnaliteJuridique === 'PP' && (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <SelectField
                        control={control}
                        name='proprietaire.indivisaire.nature_indivisaire'
                        label='Nature indivisaire'
                        values={choices?.natures_indivisaires}
                        itemMapper={(nature) => ({
                          key: nature.id,
                          label: nature.nom,
                        })}
                        renderValue={(value) => value.nom}
                        baseId='nature-indivisaire'
                        disabled={isGestionnaire}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <BaseTextField
                        control={control}
                        name='proprietaire.indivisaire.indivision.nom'
                        maxLength={256}
                        required='Indivision requise.'
                        label='Indivision'
                        disabled={isGestionnaire}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <IndivisaireTable
                        items={indivisairesList}
                        control={control}
                        disabledEdit={isGestionnaire}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }} alignItems='center'>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isDifferentReferent}
                        onChange={(e) =>
                          setIsDifferentReferent(e.target.checked)
                        }
                        disabled={isGestionnaire}
                      />
                    }
                    label='Référent technique contractuel différent du propriétaire'
                  />
                </Grid>
                {isDifferentReferent && (
                  <>
                    {isIndivisaire && personnaliteJuridique === 'PP' && (
                      <Grid item xs={12} sm={6} md={3}>
                        <IndivisaireReferentSelect
                          control={indivisaireReferentControl}
                          name='indivisaire'
                          indivisaires={indivisairesList}
                          disabled={isGestionnaire}
                          onClear={clearIndivisaireReferent}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={3}>
                      <UpperCaseTextField
                        control={control}
                        name='contrat_proprietaire.referent_technique.nom'
                        maxLength={256}
                        required={'Nom requis'}
                        disabled={referentIsIndivisaire || isGestionnaire}
                        label='Nom'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <BaseTextField
                        control={control}
                        name='contrat_proprietaire.referent_technique.prenom'
                        maxLength={256}
                        disabled={referentIsIndivisaire || isGestionnaire}
                        label='Prénom'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <BaseTextField
                        control={control}
                        name='contrat_proprietaire.referent_technique.telephone_fixe'
                        maxLength={16}
                        disabled={referentIsIndivisaire || isGestionnaire}
                        label='Téléphone fixe'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <BaseTextField
                        control={control}
                        name='contrat_proprietaire.referent_technique.telephone_portable'
                        maxLength={16}
                        disabled={referentIsIndivisaire || isGestionnaire}
                        label='Téléphone portable'
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MailTextField
                        control={control}
                        name='contrat_proprietaire.referent_technique.email'
                        maxLength={120}
                        disabled={referentIsIndivisaire || isGestionnaire}
                        label='E-mail'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AdresseField
                        control={control}
                        fieldName={
                          'contrat_proprietaire.referent_technique.adresse'
                        }
                        relatedFiedlNames={{
                          commune:
                            'contrat_proprietaire.referent_technique.commune',
                          codePostal:
                            'contrat_proprietaire.referent_technique.code_postal',
                        }}
                        required={false}
                        label={'Adresse siège'}
                        setValue={setValue}
                        disabled={referentIsIndivisaire || isGestionnaire}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <BaseTextField
                        control={control}
                        name='contrat_proprietaire.referent_technique.commune'
                        maxLength={256}
                        disabled={referentIsIndivisaire || isGestionnaire}
                        label='Commune'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <CodePostalTextField
                        control={control}
                        name='contrat_proprietaire.referent_technique.code_postal'
                        disabled={referentIsIndivisaire || isGestionnaire}
                        label='Code postal'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <DepartementField
                        label={'Département'}
                        departements={choices?.departements}
                        control={control}
                        disabled={isGestionnaire}
                        departementFieldName={
                          'contrat_proprietaire.referent_technique.departement'
                        }
                        codePostalFieldName={
                          'contrat_proprietaire.referent_technique.code_postal'
                        }
                        setValue={setValue}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
                <Grid item xs={12}>
                  <DividerTitle text={'Interlocuteurs'} />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
                <Grid item xs={12} sm={8} md={4}>
                  <SelectField
                    control={control}
                    name='propriete.cmf_neosylva'
                    required='CMF Néosylva requis.'
                    label='CMF Néosylva'
                    values={cmfUsers}
                    itemMapper={(user) => ({
                      key: user.username,
                      label: `${user.first_name} ${user.last_name}`,
                    })}
                    baseId='neosylva-cmf'
                    disabled={isGestionnaire}
                    renderValue={(value) =>
                      `${value.first_name} ${value.last_name}`
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={4}>
                  <GestionnaireAutocompleteField
                    control={control}
                    disabled={isGestionnaire}
                    name={'propriete.gestionnaire'}
                    verboseName={'Gestionnaire'}
                    gestionnaires={gestionnaires}
                    defaultGestionnaire={gestionnaire}
                    setValue={setValue}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={4}>
                  <DatePickerField
                    control={control}
                    name='contrat_proprietaire.date_visite_gestionnaire'
                    label='Date de visite du Gestionnaire'
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
                <Grid item xs={12}>
                  <DividerTitle text={'Informations propriété'} />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
                <Grid item xs={12} md={5}>
                  <BaseTextField
                    control={control}
                    name='propriete.nom_commun'
                    maxLength={256}
                    required='Nom de la propriété requise.'
                    label='Nom de la propriété'
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <AdresseField
                    control={control}
                    fieldName={'propriete.adresse'}
                    relatedFiedlNames={{
                      commune: 'propriete.commune',
                      codePostal: 'propriete.code_postal',
                    }}
                    label={'Adresse propriété'}
                    setValue={setValue}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <BaseTextField
                    control={control}
                    name='propriete.commune'
                    maxLength={256}
                    label='Commune propriété'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CodePostalTextField
                    control={control}
                    name='propriete.code_postal'
                    label='Code postal propriété'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DepartementField
                    control={control}
                    label={'Département propriété'}
                    departements={choices?.departements}
                    departementFieldName={'propriete.departement'}
                    codePostalFieldName={'propriete.code_postal'}
                    setValue={setValue}
                    required={
                      !codePostalProprieteWatch &&
                      'Département propriété requis.'
                    }
                    disabled={!!codePostalProprieteWatch}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
                <Grid item xs={12} md={4}>
                  <AreaTextField
                    control={control}
                    name='propriete.surface_totale_propriete'
                    label='Surface totale propriété (ha)'
                  />
                </Grid>
                <Grid item xs={10}>
                  <ParcelleCadastralePressentieSousContratTable
                    items={parcellesList}
                    title={'Parcelles cadastrales pressenties sous contrat'}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
                <Grid item xs={12}>
                  <SwitchField
                    control={control}
                    name='propriete.existence_dgd'
                    label='Existence DGD'
                  />
                </Grid>
              </Grid>
            </>
          ))}
        {propriete && !contratId && (
          <>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12}>
                <DividerTitle text={'Informations propriété'} />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6} md={3}>
                <BaseTextField
                  control={control}
                  name='propriete.nom_commun'
                  maxLength={256}
                  required='Nom de la propriété requise.'
                  label='Nom de la propriété'
                />
              </Grid>
              <Grid item xs={12} sm={7} md={5}>
                <AdresseField
                  control={control}
                  fieldName={'propriete.adresse'}
                  relatedFiedlNames={{
                    commune: 'propriete.commune',
                    codePostal: 'propriete.code_postal',
                  }}
                  label={'Adresse propriété'}
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BaseTextField
                  control={control}
                  name='propriete.commune'
                  maxLength={256}
                  label='Commune propriété'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <CodePostalTextField
                  control={control}
                  name='propriete.code_postal'
                  label='Code postal propriété'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DepartementField
                  control={control}
                  label={'Département propriété'}
                  departements={choices?.departements}
                  departementFieldName={'propriete.departement'}
                  codePostalFieldName={'propriete.code_postal'}
                  setValue={setValue}
                  required={
                    !codePostalProprieteWatch && 'Département propriété requis.'
                  }
                  disabled={!!codePostalProprieteWatch}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6} md={3}>
                <AreaTextField
                  control={control}
                  name='propriete.surface_totale_propriete'
                  label='Surface totale propriété (ha)'
                />
              </Grid>
              <Grid item xs={10}>
                <ParcelleCadastralePressentieSousContratTable
                  items={parcellesList}
                  title={'Parcelles cadastrales pressenties sous contrat'}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6} md={2}>
                <SwitchField
                  control={control}
                  name='propriete.existence_dgd'
                  label='Existence DGD'
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12}>
                <DividerTitle text={'Interlocuteurs'} />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6} md={3}>
                <SelectField
                  control={control}
                  name='propriete.cmf_neosylva'
                  required='CMF Néosylva requis.'
                  label='CMF Néosylva'
                  values={cmfUsers}
                  itemMapper={(user) => ({
                    key: user.username,
                    label: `${user.first_name} ${user.last_name}`,
                  })}
                  baseId='neosylva-cmf'
                  disabled={isGestionnaire}
                  renderValue={(value) =>
                    `${value.first_name} ${value.last_name}`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <GestionnaireAutocompleteField
                  control={control}
                  disabled={isGestionnaire}
                  name={'propriete.gestionnaire'}
                  verboseName={'Gestionnaire'}
                  gestionnaires={gestionnaires}
                  defaultGestionnaire={gestionnaire}
                  setValue={setValue}
                  required={true}
                />
              </Grid>
              {contratProprietaire && (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <DatePickerField
                      control={control}
                      name='contrat_proprietaire.date_visite_gestionnaire'
                      label='Date de visite du Gestionnaire'
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
        {contratId && contratProprietaire && (
          <>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12}>
                <DividerTitle text={'Informations propriétaire'} />
              </Grid>
            </Grid>
            <Grid container item spacing={2} mt={1}>
              <Grid item xs={12}>
                <ProprietaireTable items={proprietairesList} />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <DividerTitle text='Référent technique' />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ReferentTechniqueFormDialog
                  item={referentTechniqueWatch}
                  onSubmit={(value) =>
                    setValue('contrat_proprietaire.referent_technique', value)
                  }
                  proprietairesContactIds={
                    proprietairesList?.map((item) => item.contact.id)
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </TabPanelForm>
  );
}

RenseignementsGenerauxTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  choices: PropTypes.object,
  proprieteState: PropTypes.object.isRequired,
  gestionnaireState: PropTypes.object.isRequired,
  contratProprietaireState: PropTypes.object.isRequired,
  isIndivisaireState: PropTypes.object.isRequired,
  proprietaireState: PropTypes.object.isRequired,
  proprietairesList: PropTypes.array,
  indivisairesList: PropTypes.array,
  parcellesList: PropTypes.array,
  emptyContact: PropTypes.object.isRequired,
  contratId: PropTypes.string,
};
